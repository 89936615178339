<script>
export default {
  name: "NavBar"
}
</script>

<template >
  <header class="bg-dark-custom">
    <nav class="text-silver font-['Lato'] text-sm flex flex-row justify-between mr-6 ml-6 pt-6 pb-4 ">
      <h1 class="flex-item font-bold"> <span class="text-xl font-['Montserrat']">Portfolio </span> <span class="font-bold text-lg">|</span> Thomas Bénas</h1>
        <div class="lg:flex gap-x-8" >
            <div class="flex-item"><a href="/">Home</a></div>
            <!-- <div class="flex-item"><a href="#">Mes projets</a>s</div> -->
            <!--<div class="flex-item"><a href="#">A propos</a></div>-->
        </div>
        <!--<button type="button" class="w-8 h-8 lg:hidden md:hidden sm:flex items-center justify-center hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-300">
            <svg width="24" height="24" fill="none" aria-hidden="true">
                <path d="M12 6v.01M12 12v.01M12 18v.01M12 7a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
        </button>-->
    </nav>


  </header>
</template>
