<script>
import NavBar from "@/components/NavBar";
import PresensationSection from "@/components/PresensationSection";
import DiplomesSection from "@/components/DiplomesSection";

export default {
  components: {
    DiplomesSection,
    PresensationSection,
    NavBar,
  }
}
</script>

<template class="bg-dark-custom">
  <NavBar />
  <PresensationSection />
  <DiplomesSection />

</template>
