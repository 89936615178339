<script>
export default {
  name: "PresensationSection",
  data() {
    return {
      text_presentation: "Je suis actuellement étudiant en Master informatique BDIA à Dijon, en alternance au Laboratoire Informatique de Bourgogne (LIB). Mon travail consiste à élaborer des solutions informatiques permettant de collecter, analyser et réduire l'impact environnemental des émissions de GES au sein de l'université."
    }
  }
}
</script>

<template>

  <div class="bg-dark-custom text-silver pl-6 pr-6">
    <div class="flex flex-wrap items-center justify-center gap-x-8 pt-10 pb-20">
      <img class="w-36" :src="require('@/assets/thumbnail.png')"/>
      <div>
          <div class="flex flex-col gap-y-4 align-top pt-5">
            <h2 class="font-['Lato'] font-bold text-lg">Bonjour !</h2>
            <p class="max-w-prose text-justify">{{ text_presentation }}</p>
            <div class="flex gap-4 justify-end">
              <a href="https://www.linkedin.com/in/thomas-benas/"><img class="w-9" :src="require('@/assets/linkedIn.svg')"/></a>
              <a href="mailto:thomas@benas.fr/"><img class="w-9" :src="require('@/assets/mail.svg')"/></a>
            </div>
          </div>
      </div>
    </div>
  </div>

</template>

